import React, { FC, useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  ErrorType,
  ListItemType,
  SocialLinkItemType
} from 'cellar/src/ts-types'
import InputFileUpload from '../../../components/UI/FileUpload'
import BasicSelect from '../../../components/UI/Select'
import AddressesList from 'cellar/src/components/AddressesList'
import ProjectTypeSelector from '../../../components/ProjectTypeSelector'

import SocialLinks from 'cellar/src/components/SocialLinks'
import CustomizedDialogs from 'cellar/src/components/UI/Dialog'
import {
  contractAddressInitialState,
  projectInitialFields,
  getErrorMessage,
  FormStateType,
  validateValue,
  allValidateValue,
  getFileFromUrl,
  isWebSite,
  getContractAddress
} from 'cellar/src/utils'
import { IProject } from '@repo/types'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { createProject, updateProject } from '../../../api'

const MAX_FILE_SIZE = 50000000

interface ICreateEditForm {
  handleClose: () => void
  data: boolean | IProject
  afterCreateEdit: () => void
}

const CreateEditForm: FC<ICreateEditForm> = ({
  handleClose,
  data,
  afterCreateEdit
}) => {
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [state, setState] = useState<any>(projectInitialFields(data))
  const [contractAddressesState, setContractAddressesState] = useState<
    ListItemType[]
  >(
    contractAddressInitialState(
      data ? (data as IProject).contractAddresses : null
    )
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name in state) {
      const newState: any = validateValue(state, name, value)
      setState(newState)
    }
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.stopPropagation()
    const files = event.target.files

    if (files && files[0]) {
      if (files[0].size > MAX_FILE_SIZE) {
        const newState = {
          ...state,
          logo: {
            ...state.logo,
            value: '',
            error: 'File size exceeds 50MB.',
            touched: true
          }
        }
        setState(newState)
      } else {
        const newState = {
          ...state,
          logo: {
            ...state.logo,
            value: files[0],
            error: false,
            touched: false
          }
        }
        setState(newState)
      }
    }
  }

  const linksHandleChange = (value: SocialLinkItemType[]) => {
    const newState = {
      ...state,
      socialLinks: {
        ...state.socialLinks,
        value: value,
        error: false,
        touched: false
      }
    }
    setState(newState)
  }

  const deleteLogo = () => {
    const newState = {
      ...state,
      logo: {
        ...state.logo,
        value: '',
        error: false,
        touched: false
      }
    }
    setState(newState)
  }

  const getLogoFile = () => {
    if (typeof data === 'object' && typeof state.logo.value === 'string') {
      return getFileFromUrl(state.logo.value)
    } else {
      return state.logo.value
    }
  }

  const socialLinksIsValid = () => {
    let valid = false
    const newSocialLinks = state.socialLinks.value.map((el: any) => {
      const linkIsValid = isWebSite(el.value)
      if (typeof linkIsValid === 'string') {
        valid = true
        return { value: el.value, error: linkIsValid }
      } else {
        return { value: el.value, error: '' }
      }
    })

    return valid ? newSocialLinks : false
  }

  const onConfirm = () => {
    const { name, description, typeId, website, logo } = state

    const result = allValidateValue<FormStateType>({
      name,
      description,
      typeId,
      website,
      logo
    })

    if (result) {
      if (!state.socialLinks.value.length) {
        //@ts-ignore
        result.socialLinks = {
          value: state.socialLinks.value,
          error: 'Social links is required',
          touched: true
        }
      } else {
        const newSocialLinks = socialLinksIsValid()
        //@ts-ignore
        result.socialLinks = {
          ...state.socialLinks,
          value: newSocialLinks ? newSocialLinks : [...state.socialLinks.value]
        }
      }
      setState(result as FormStateType)
    } else {
      const socialLinksValidation = socialLinksIsValid()
      if (state.socialLinks.value.length && !socialLinksValidation) {
        ;(async () => {
          try {
            const logoFile = await getLogoFile()
            const contractAddress: null | string[] = getContractAddress(
              contractAddressesState
            )

            const formData: any = new FormData()
            formData.append('name', state.name.value)
            formData.append('logo', logoFile)
            formData.append('description', state.description.value)
            formData.append('typeId', state.typeId.value)
            formData.append('website', state.website.value)
            state.socialLinks.value.forEach((el: any, index: number) => {
              formData.append(`social_links[${index}]`, el.value)
            })

            if (contractAddress) {
              contractAddress.forEach((el: string, index: number) => {
                formData.append(`contractAddresses[${index}]`, el)
              })
            } else {
            }

            if (typeof data === 'object') {
              await updateProject(formData, (data as IProject)?._id as string)
            } else {
              await createProject(formData)
            }
            afterCreateEdit()
          } catch (error: unknown) {
            const message: ErrorType = getErrorMessage(error)
            setErrorMessage(message)
            setTimeout(() => {
              setErrorMessage('')
            }, 6000)
          }
        })()
      } else {
        setState({
          ...state,
          socialLinks: {
            value: socialLinksValidation,
            error: state.socialLinks.value.length
              ? false
              : 'Social links is required',
            touched: true
          }
        })
      }
    }
  }

  //@ts-ignore
  const onAutocompleteTypeChange = (
    //@ts-ignore
    event: React.SyntheticEvent,
    //@ts-ignore
    value: string
  ) => {
    //@ts-ignore
    onChange({ target: { name: 'typeId', value: value } })
  }

  const handleAddressChange = (name: string, newLinks: ListItemType[]) => {
    setContractAddressesState(newLinks)
  }

  return (
    <CustomizedDialogs
      open={!!data}
      maxWidth="sm"
      handleClose={handleClose}
      title={typeof data === 'object' ? 'EDIT PROJECT' : 'CREATE PROJECT'}
      confirmTitle="Yes"
      onConfirm={onConfirm}
    >
      <form>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              autoFocus
              fullWidth
              label="Name"
              name="name"
              value={state.name.value}
              error={!!state.name.error}
              helperText={state.name.error}
              onChange={onChange}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              fullWidth
              label="Website"
              name="website"
              value={state.website.value}
              helperText={state.website.error}
              error={!!state.website.error}
              onChange={onChange}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              fullWidth
              multiline
              rows={2}
              name="description"
              label="Description"
              value={state.description.value}
              helperText={state.description.error}
              error={!!state.description.error}
              onChange={onChange}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <ProjectTypeSelector
              defaultValue={state.typeId.value}
              error={state.typeId.error}
              onInputChange={onAutocompleteTypeChange}
              name="typeId"
            />
          </Grid>
          <Grid size={{ xs: 12 }} sx={{ alignContent: 'center' }}>
            {state.logo.value ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  placeContent: 'space-between'
                }}
              >
                <Typography variant="body2">
                  Logo uploaded successfully
                </Typography>
                <IconButton onClick={deleteLogo}>
                  <DeleteOutlineOutlinedIcon color="error" />
                </IconButton>
              </Box>
            ) : (
              <InputFileUpload
                error={state.logo.error as string}
                onChange={handleFileChange}
                multiple={false}
              />
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <SocialLinks
              error={state.socialLinks.error}
              handleChange={linksHandleChange}
              links={state.socialLinks.value}
            />
          </Grid>

          <Grid size={{ xs: 12, sm: 6 }}>
            <AddressesList
              name="contractAddresses"
              links={contractAddressesState}
              label="Contract Addresses"
              handleChange={handleAddressChange}
            />
          </Grid>

          <Grid>
            <Typography variant="body2" color="error">
              {errorMessage ? errorMessage : ''}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </CustomizedDialogs>
  )
}

export default CreateEditForm
