import React, { FC, useState } from 'react'
import { allValidateValue, stepsMainStateInitialFields } from 'cellar/src/utils'
import { ICreationForm } from '../ts-types'
import {
  ICreateOffChainStepInput,
  ICreateOnChainStepInput,
  IOffChainStepInput,
  IOnChainStepInput,
  IQuest,
  IStep,
  StepTypeEnum
} from '@repo/types'
import CreationFormUi from './CreationFormUi'
import { useQueryParams } from '../../../hooks'

interface ICreationFormProps {
  disabled: boolean
  loading: boolean
  quests: IQuest[] | null
  title: string
  errorMessage: string | null
  isEdit: boolean
  onConfirm: (
    body: ICreateOnChainStepInput | ICreateOffChainStepInput,
    isOnChain: boolean,
    isEdit: boolean,
    img: null | File,
    imgId: null | string
  ) => void
  stepData?: IStep | null
}

const CreationFormContainer: FC<ICreationFormProps> = ({
  disabled,
  loading,
  quests,
  title,
  errorMessage,
  onConfirm,
  isEdit,
  stepData
}) => {
  const { getQueryParam } = useQueryParams()
  const selectedQuest: string | undefined | null = getQueryParam('quest-id')
  const [mainState, setMainState] = useState<ICreationForm>(
    stepsMainStateInitialFields(stepData ? stepData : null, selectedQuest)
  )

  const mainStateChange = (name: string, value: string | number) => {
    setMainState({
      ...mainState,
      [name]: {
        touched: true,
        error: !value,
        value: value,
        validations: { required: true }
      }
    })
  }

  const getChildState = <T extends IOffChainStepInput | IOnChainStepInput>(
    state: T,
    img: null | File
  ): void => {
    //@ts-ignore
    const addressIsValid = allValidateValue<ICreationForm>(mainState)

    if (addressIsValid) {
      setMainState(addressIsValid as ICreationForm)
    } else {
      const isOnChain = mainState.type.value === 'on_chain'
      const body: ICreateOnChainStepInput | ICreateOffChainStepInput = {
        quest: mainState.quest.value,
        type: mainState.type.value as StepTypeEnum,
        xp: Number(mainState.xp.value),
        ...(isOnChain
          ? { onChainStep: state as IOnChainStepInput }
          : { offChainStep: state as IOffChainStepInput })
      }

      onConfirm(
        body,
        isOnChain,
        isEdit,
        img,
        stepData?.media ? (stepData?.media as string) : null
      )
    }
  }

  return (
    <CreationFormUi
      quests={quests}
      editStep={stepData ? stepData : null}
      disabled={disabled}
      mainStateChange={mainStateChange}
      mainState={mainState}
      errorMessage={errorMessage}
      loading={loading}
      selectedQuest={selectedQuest}
      getChildState={getChildState}
      confirmTitle={title}
      isEdit={isEdit}
    />
  )
}

export default CreationFormContainer
