import fetchClient from './fetchClient'
import {
  IListAdminResponse,
  ICreateAdminResponse,
  ICreateAdminInput,
  ISignInAdminInput,
  ISignInAdminResponse,
  IDeleteAdminResponse,
  IUpdateAdminInput,
  IUpdateAdminResponse,
  IResetAdminPassword,
  IResetAdminPasswordResponse,
  ICreateProjectInput,
  IListProjectResponse,
  IDeleteProjectResponse,
  IUpdateProjectResponse,
  IUpdateProjectInput,
  IListProjectTypeResponse,
  IListQuestResponse,
  IProjectType,
  IDeleteProjectTypeResponse,
  IListQuestInput,
  ICreateQuestInput,
  ICreateQuestResponse,
  IUpdateQuestResponse,
  IDeleteQuestResponse,
  IChangeQuestStatusResponse,
  IChangeProjectStatusResponse,
  ICreateOffChainStepResponse,
  ICreateOffChainStepInput,
  QuestStatusEnum,
  ProjectStatusEnum,
  ICreateOnChainStepInput,
  ICreateOnChainStepResponse,
  IListStepInput,
  IListStepResponse,
  IDeleteStepResponse,
  IStep,
  IQuest,
  IGetSettingsResponse,
  IReferralListResponse,
  IListUserResponse
} from '@repo/types'
import { generateSearchParam } from '../utils'

export async function login({
  email,
  password
}: ISignInAdminInput): Promise<ISignInAdminResponse> {
  return fetchClient('auth/admin/signin', {
    method: 'POST',
    body: { email, password },
    authRequired: false
  })
}

export async function resetPassword({
  oldPassword,
  newPassword
}: IResetAdminPassword): Promise<IResetAdminPasswordResponse> {
  return fetchClient('auth/admin/reset-password', {
    method: 'PATCH',
    body: { oldPassword: oldPassword, newPassword: newPassword }
  })
}

export async function getUsers(): Promise<IListAdminResponse> {
  return fetchClient('admins')
}

// export async function getUser(id: string): Promise<IAdmin> {
//   return fetchClient('admins/' + id)
// }

export async function createUser({
  email,
  password,
  nickname
}: ICreateAdminInput): Promise<ICreateAdminResponse> {
  return fetchClient('auth/admin', {
    method: 'POST',
    body: { email, password, nickname },
    authRequired: true
  }) as Promise<ICreateAdminResponse>
}

export async function deleteAdmin(id: string): Promise<IDeleteAdminResponse> {
  return fetchClient(`admins/${id}`, {
    method: 'DELETE'
  })
}

export async function updateUser({
  id,
  body
}: {
  id: string | undefined
  body: IUpdateAdminInput
}): Promise<IUpdateAdminResponse> {
  return fetchClient(`admins/${id}`, {
    method: 'PUT',
    body,
    authRequired: true
  })
}

interface IClientListProjectInput {
  page?: number
  perPage?: number
  query?: string
  typeId?: string
}

export async function getProjects({
  page,
  perPage,
  query,
  //@ts-ignore
  typeId
}: IClientListProjectInput): Promise<IListProjectResponse> {
  return fetchClient(
    'projects?' + generateSearchParam({ page, perPage, query, typeId })
  )
}

export async function getSteps({
  page,
  perPage,
  query,
  questId
}: IListStepInput): Promise<IListStepResponse> {
  return fetchClient(
    'steps?' + generateSearchParam({ page, perPage, query, questId })
  )
}

export async function deleteProject(
  id: string
): Promise<IDeleteProjectResponse> {
  return fetchClient(`projects/${id}`, {
    method: 'DELETE'
  })
}

export async function createProject(
  formData: ICreateProjectInput
): Promise<IResetAdminPasswordResponse> {
  return fetchClient('projects/', {
    method: 'POST',
    body: formData,
    noContentType: true
  })
}

export async function updateProject(
  formData: IUpdateProjectInput,
  id: string
): Promise<IUpdateProjectResponse> {
  return fetchClient(`projects/${id}`, {
    method: 'PUT',
    body: formData,
    noContentType: true
  })
}

export async function getProjectTypes(): Promise<IListProjectTypeResponse> {
  return fetchClient('project-types?')
}

export async function createProjectTypes(name: string): Promise<IProjectType> {
  return fetchClient('project-types', {
    method: 'POST',
    body: { name }
  })
}

export async function updateProjectTypes(
  name: string,
  id: string
): Promise<IProjectType> {
  return fetchClient('project-types/' + id, {
    method: 'PUT',
    body: { name }
  })
}

export async function deleteProjectTypes(
  id: string
): Promise<IDeleteProjectTypeResponse> {
  return fetchClient('project-types/' + id, {
    method: 'DELETE'
  })
}

export async function getQuests({
  page,
  perPage,
  projectId,
  query
}: IListQuestInput): Promise<IListQuestResponse> {
  return fetchClient(
    'quests?' + generateSearchParam({ page, perPage, query, projectId })
  )
}

export async function getQuestById(id: string): Promise<IQuest> {
  return fetchClient('quests/' + id)
}

export async function createQuests(
  formData: ICreateQuestInput
): Promise<ICreateQuestResponse> {
  return fetchClient(`quests?`, {
    method: 'POST',
    body: formData,
    noContentType: true
  })
}

export async function updateQuests(
  formData: ICreateQuestInput,
  id: string
): Promise<IUpdateQuestResponse> {
  return fetchClient(`quests/${id}`, {
    method: 'PUT',
    body: formData,
    noContentType: true
  })
}

export async function deleteQuests(id: string): Promise<IDeleteQuestResponse> {
  return fetchClient(`quests/${id}`, {
    method: 'DELETE'
  })
}

export async function updateQuestStatus(
  id: string,
  status: QuestStatusEnum
): Promise<IChangeQuestStatusResponse> {
  return fetchClient(`quests/${id}/status`, {
    method: 'PATCH',
    body: { status: status }
  })
}

export async function updateProjectStatus(
  id: string,
  status: ProjectStatusEnum
): Promise<IChangeProjectStatusResponse> {
  return fetchClient(`projects/${id}/status`, {
    method: 'PATCH',
    body: { status: status }
  })
}

export async function createOffChainStep(
  body: ICreateOffChainStepInput
): Promise<ICreateOffChainStepResponse> {
  return fetchClient(`steps/offchain`, {
    method: 'POST',
    body: body
  })
}

export async function editOffChainStep(
  body: ICreateOffChainStepInput,
  id: string
): Promise<ICreateOffChainStepResponse> {
  return fetchClient(`steps/offchain/${id}`, {
    method: 'PUT',
    body: body
  })
}

export async function editOffChainStepImg(
  body: { image: File },
  id: string
): Promise<ICreateOffChainStepResponse> {
  return fetchClient(`steps/${id}/media`, {
    method: 'PUT',
    body: body,
    noContentType: true
  })
}

export async function deleteOffChainStepImg(
  id: string
): Promise<ICreateOffChainStepResponse> {
  return fetchClient(`steps/${id}/media`, {
    method: 'DELETE'
  })
}

export async function createOnChainStep(
  body: ICreateOnChainStepInput
): Promise<ICreateOnChainStepResponse> {
  return fetchClient(`steps/onchain`, {
    method: 'POST',
    body: body
  })
}

export async function editOnChainStep(
  body: ICreateOnChainStepInput,
  id: string
): Promise<ICreateOffChainStepResponse> {
  return fetchClient(`steps/onchain/${id}`, {
    method: 'PUT',
    body: body
  })
}

export async function deleteStep(id: string): Promise<IDeleteStepResponse> {
  return fetchClient(`steps/${id}`, {
    method: 'DELETE'
  })
}

export async function getStep(id: string): Promise<IStep> {
  return fetchClient(`steps/${id}`)
}

export async function getSettings(): Promise<IGetSettingsResponse> {
  return fetchClient(`settings`)
}

export async function getReferrals({
  page,
  perPage
}: {
  page: number
  perPage: number
}): Promise<IReferralListResponse> {
  return fetchClient(`users/referrals/list?page=${page}&perPage=${perPage}`)
}

export async function resetXp(
  referralTransactionId: string
): Promise<IReferralListResponse> {
  return fetchClient(`users/${referralTransactionId}/reset-xp`, {
    method: 'POST'
  })
}

export async function getUsersList({
  page,
  perPage
}: {
  page: number
  perPage: number
}): Promise<IListUserResponse> {
  return fetchClient(`users/list?page=${page}&perPage=${perPage}`)
}

export async function updateSettings(body: {
  referralPercentage: number
  streak: number
  streakXp: number
}): Promise<IGetSettingsResponse> {
  return fetchClient('settings', {
    method: 'PATCH',
    body: body
  })
}
