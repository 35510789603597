import {
  OffChainStepTypeEnum,
  OnChainStepTypeEnum,
  QuizTypeEnum,
  StepOnChainAssetTypeEnum,
  StepTypeEnum
} from '../../enums'

import { Types } from 'mongoose'
import { IQuest } from '../quest'
import { IPaginationDto, IPaginationResponseDto } from '../pagination'

export interface IOptionsInput extends IOptions {}

export interface IOptionInput extends IOption {}

export interface IOptions {
  id: number
  options: IOption[]
  correctAnswers: number[]
}
export interface IOption {
  id: number
  text: string
}

export interface IQuizInput extends IQuiz {}

export interface IQuiz {
  id?: number
  text: string
  type: QuizTypeEnum
  options: IOption[]
  correctAnswers: number[]
}

export interface IInformationInput extends IInformation {}

export interface IInformation {
  title: string
  content: string
}

export interface IOffChainStepInput {
  name: string
  type: OffChainStepTypeEnum
  description: string
  information?: IInformationInput
  quiz?: IQuizInput[]
}

export interface IOffChainStep {
  name: string
  type: OffChainStepTypeEnum
  description: string
  information?: IInformation
  quiz?: IQuiz[]
}

export interface ICreateOffChainStepInput {
  quest: string
  type: StepTypeEnum
  xp: number
  offChainStep: IOffChainStepInput
}

export interface IOptionsResponse {
  id: number
  options: IOptionResponse[]
  correctAnswers: number[]
}

export interface IOptionResponse {
  id: number
  text: string
}

export interface IQuizResponse {
  id?: number
  text: string
  type: QuizTypeEnum
  options: IOptionResponse[]
  correctAnswers: number[]
}

export interface IInformationResponse {
  title: string
  content: string
}

export interface IOffChainStepResponse {
  name: string
  type: OffChainStepTypeEnum
  description: string
  information?: IInformationResponse
  quiz?: IQuizResponse[]
}

export interface ICreateOffChainStepResponse {
  _id: string
  quest: string
  type: StepTypeEnum
  xp: number
  offChainStep: IOffChainStepResponse
  createdBy: string
  createdAt: Date
  updatedAt: Date
}

export interface IStepAssetDetailsInput {
  type: StepOnChainAssetTypeEnum
  amount?: number
  tokenId?: string
}

export interface IStepLinkInput {
  text?: string
  url?: string
}

export interface IOnChainStepInput {
  name: string
  description: string
  actionType: OnChainStepTypeEnum
  assetDetails: IStepAssetDetailsInput
  linkToStep?: IStepLinkInput
}

export interface IOnChainStep {
  name: string
  description: string
  actionType: OnChainStepTypeEnum
  assetDetails: IStepAssetDetails
  linkToStep?: IStepLink
}

export interface IStep {
  _id?: string
  quest: Types.ObjectId | IQuest
  type: StepTypeEnum
  media?: string
  onChainStep?: IOnChainStep
  offChainStep?: IOffChainStep
  xp: number
  createdBy: Types.ObjectId
  createAt?: Date
  updateAt?: Date
}

export interface ICreateOnChainStepInput {
  quest: string
  type: StepTypeEnum
  onChainStep?: IOnChainStepInput
  xp: number
}

export interface IStepAssetDetailsResponse extends IStepAssetDetails {}

export interface IStepAssetDetails {
  type: StepOnChainAssetTypeEnum
  amount?: number
  tokenId?: string
}

export interface IStepLinkResponse extends IStepLink {}

export interface IStepLink {
  text?: string
  url?: string
}

export interface IOnChainStepResponse {
  name: string
  description: string
  actionType: OnChainStepTypeEnum
  assetDetails: IStepAssetDetailsResponse
  linkToStep?: IStepLinkResponse
}

export interface ICreateOnChainStepResponse {
  quest: string
  type: StepTypeEnum
  onChainStep?: IOnChainStepResponse
  xp: number
  createdBy: string
  createdAt: Date
  updatedAt: Date
}

export interface IListStepResponse extends IPaginationResponseDto {
  data: IStep[]
}

export interface IListStepInput extends IPaginationDto {
  query?: string
  questId?: string
}

export interface IExplorerStepInput {
  questId: string
}

export interface IVerifyQuiz {
  id: number
  correctAnswers: number[]
}

export interface IDeleteStepResponse {
  result: boolean
}

export class IPartialOptionInput {
  id?: number
  text?: string
}
export class IPartialQuizInput {
  id?: number
  text?: string
  type?: QuizTypeEnum
  options?: IPartialOptionInput[]
  correctAnswers?: number[]
}
export class IPartialInformationInput {
  title?: string
  content?: string
}
export class IPartialOffChainStepInput {
  name?: string
  type?: OffChainStepTypeEnum
  description?: string
  information?: IPartialInformationInput
  quiz?: IPartialQuizInput[]
}

export interface IUpdateOffChainStepInput {
  quest?: string
  type?: StepTypeEnum
  xp?: number
  offChainStep?: IPartialOffChainStepInput
}
export class IPartialStepAssetDetailsInput {
  type?: StepOnChainAssetTypeEnum
  amount?: number
  tokenId?: string
}

export interface IPartialStepLinkInput {
  text?: string
  url?: string
}

export interface IPartialOnChainStepInput {
  name?: string
  description?: string
  actionType?: OnChainStepTypeEnum
  assetDetails?: IPartialStepAssetDetailsInput
  linkToStep?: IPartialStepLinkInput
}

export interface IUpdateOnChainStepInput {
  quest?: string
  type?: StepTypeEnum
  onChainStep?: IPartialOnChainStepInput
  xp?: number
}

export interface ICompletedListStepInput {
  questId: string
}
export interface IVerifyStepResponse {
  _id?: Types.ObjectId | string
  quest: Types.ObjectId
  step: Types.ObjectId
  userId: Types.ObjectId
  xp: number
  createdAt?: Date
  updatedAt?: Date
}

export interface IListCompletedStepsResponse {
  data: IVerifyStepResponse[]
}
