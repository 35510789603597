import React, { FC, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CancelIcon from '@mui/icons-material/Cancel'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Box from '@mui/material/Box'
import { ImageListItem } from 'cellar/src/ts-types'
import { Container, ImgItem } from './styles'
import { sliceString } from '../../utils'

interface IImagesList {
  images: ImageListItem[]
  fullWidth?: boolean
  onDelete: (img?: ImageListItem, index?: number) => void
  isVideo?: boolean
  videoType?: string
  video?: any
}

const ImagesList: FC<IImagesList> = ({
  images,
  fullWidth,
  onDelete,
  isVideo,
  videoType,
  video
}) => {
  const [model, setModal] = useState(false)

  return (
    <Box sx={{ justifyItems: 'center', width: fullWidth ? '100%' : '' }}>
      {images.map((img, index) => (
        <Container key={img.src}>
          <Box sx={{ display: 'flex' }}>
            {isVideo ? (
              ''
            ) : (
              <ImgItem src={img.src} alt="IMG" width={18} height={18} />
            )}
            <Typography sx={{ fontSize: '12px' }}>
              {sliceString(img.name ? img.name : '', 20)}
            </Typography>
          </Box>
          <CancelIcon
            onClick={() => {
              onDelete(img, index)
            }}
            cursor="pointer"
            sx={{ color: '#E6E6E6', fontSize: '19px' }}
          />
        </Container>
      ))}
      {isVideo ? (
        <Container>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontSize: '12px' }}>Video</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <PlayArrowIcon
              onClick={() => {
                setModal(true)
              }}
              cursor="pointer"
              sx={{ color: '#E6E6E6', fontSize: '21px' }}
            />
            <CancelIcon
              onClick={() => {
                onDelete()
              }}
              cursor="pointer"
              sx={{ color: '#E6E6E6', fontSize: '19px', marginLeft: '10px' }}
            />
          </Box>
          {model && (
            <Dialog
              aria-labelledby="customized-dialog-title"
              onClose={() => {
                setModal(false)
              }}
              open={model}
            >
              <div>
                <video width="100%" height="auto" controls autoPlay>
                  <source src={video} type={videoType as string} />
                  Your browser does not support the video tag. //{' '}
                </video>
              </div>
            </Dialog>
          )}
        </Container>
      ) : (
        ''
      )}
    </Box>
  )
}

export default ImagesList
