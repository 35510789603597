export enum errors {
  projectWithThisNameAlreadyExists = 'PROJECT_WITH_THIS_NAME_ALREADY_EXISTS',
  projectNotFound = 'PROJECT_NOT_FOUND',
  projectTypeExists = 'PROJECT_TYPE_EXISTS',
  projectTypeNotFound = 'PROJECT_TYPE_NOT_FOUND',
  projectTypeCanNotBeDeleted = 'PROJECT_TYPE_CAN_NOT_BE_DELETED',
  logoFileIsRequired = 'LOGO_FILE_IS_REQUIRED',
  wrongPassword = 'WRONG_PASSWORD',
  adminNotFound = 'ADMIN_NOT_FOUND',
  imageIsRequired = 'IMAGE_IS_REQUIRED',
  mediaIsRequired = 'MEDIA_IS_REQUIRED',
  startDateMustBeEarlierThenEndDate = 'START_DATE_MUST_BE_EARLIER_THEN_END_DATE',
  questNotFound = 'QUEST_NOT_FOUND',
  questNotFoundOrInactive = 'QUEST_NOT_FOUND_OR_INACTIVE',
  activeStatusNotAllowed = 'ACTIVE_STATUS_NOT_ALLOWED',
  quizMustBeProvidedWhenTypeIsQuiz = 'QUIZ_MUST_BE_PROVIDED_WHEN_TYPE_IS_QUIZ',
  informationMustBeProvidedWhenTypeIsInformation = 'INFORMATION_MUST_BE_PROVIDED_WHEN_TYPE_IS_INFORMATION',
  stepNotFound = 'STEP_NOT_FOUND',
  stepTypeMissMatch = 'STEP_TYPE_MISS_MATCH',
  offChainStepTypeMissMatch = 'OFF_CHAIN_STEP_TYPE_MISS_MATCH',
  userNotFound = 'USER_NOT_FOUND',
  eachQuizItemMustHaveAnId = 'EACH_QUIZ_ITEM_MUST_HAVE_AN_ID',
  quizWithThisIdNotFOUND = 'QUIZ_WITH_THIS_ID_NOT_FOUND',
  referralTransactionNotFound = 'REFERRAL_TRANSACTION_NOT_FOUND',
  streakLimitReached = 'STREAK_LIMIT_REACHED',
  streakNotAllowed = 'STREAK_NOT_ALLOWED'
}
